.legal-page-content {
  max-width: 800px;
  margin: 0 auto; /* Centers the content */
  margin-top: 100px;
  padding: 0 20px; /* Adds some padding on the sides */
  line-height: 1.6;
  font-size: 1rem;
  font-family: Arial, sans-serif;
  color: #333;
}

/* body {
  background-color: white !important;
} */

h1, h2, p, td, th, tr, ul, li {
  color: white;
}
