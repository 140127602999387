.form-container {
  padding: 40px;
}

.form-prompt {
  text-align: center;
  font-family: Bolota;
  font-weight: bold;
  font-size: 24px;
}

.form-description {
  width: 100%;
  /* text-wrap:pretty; */
  text-align: center;
  font-family: Bolota;
  font-weight: lighter;
  font-size: 14px;
}

.close-button {
  background-color: #463442 !important;
  color: #D3E2A2 !important;
}

.hs-input {
  height: 48px;
  width: 250px;
  margin: 0 auto;
  display: block;
  text-align: center;
  background-color: #F7F2EB;
  font-family: Bolota;
  font-weight: lighter;
  border-radius: 20px;
  border: 0;
  margin-bottom: 20px;
  margin-top: 30px;
}

.hs-button, #hs-modal-accept-all, #hs-modal-accept-all, #hs-modal-save-settings{
  margin: 0 auto;
  display: block;
  text-align: center;
  cursor: pointer;
  padding: 15px 15px 10px 15px;
  border-radius: 20px;
  border: 0;
  font-family: Bolota;
  font-weight: 200;
  font-size: large;
  color: #463442;
  background-color: #D3E2A2;
}

#hs-modal-save-settings {
  background-color: #463442;
  color: #D3E2A2;
}

.hs-error-msgs {
  list-style-type: none;
  font-size: 12px;
  text-align: center;
  padding: 0px;
}

@media screen and (min-width: 200px) and (max-width: 800px) { 
  /* CSS rules for small devices */
  .signup-modal {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #FF5622;
    z-index: 99;
    padding-top: 150px;
    height: 100vh;
    width: 100vw;
    transition: all 200ms ease-in-out;
  }

  .signup-form {
    min-width: 100%;
    margin-left: 0px;
    padding: 0px;
  }

  /* .hs-input {
    background-color: ;
  } */
}

@media screen and (min-width: 801px) {
  .signup-modal {
    position: absolute;
    min-width: 700px;
    max-width: 700px;
    min-height: 500px;
    max-height: 500px;
    padding: 40px 30px 30px 30px;
    top: calc((100vh - 500px)/2);
    left: calc((100vw - 740px)/2);
    background-image: url('./blob-orange.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}