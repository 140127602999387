.language-picker {
  z-index: 99;
  position: absolute;
}

button {
  all: unset;
}
.DropdownMenuContent{
  min-width: 100px;
  background-color: #F7F2EB;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.DropdownMenuContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuRadioItem
{
  font-size: 15px;
  line-height: 1;
  color: #463442;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
  font-family: Bolota;
  cursor: pointer;
}
.DropdownMenuRadioItem > img {
  margin-right: 5px;
}
.DropdownMenuRadioItem[data-highlighted]{
  background-color: #D3E2A2;
  color: #463442;
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: #463442;
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: #463442;
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  height: 35px;
  width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Bolota;
  font-size: 20px;
}
.IconButton > span {
  margin-left: 5px;
  color:#D3E2A2;
  text-shadow: #463442 0.5px 0.5px 1px;
}
.IconButton:hover {
  cursor:pointer;
}
.IconButton:focus {
  box-shadow: 0 0 0 transparent;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: #463442;
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
}


@media screen and (min-width: 200px) and (max-width: 800px) { 
  .language-picker {
    left: 40px;
    top: 45px;
  }
  .IconButton {
    width: 40px;
    font-size: 14px;
  }
  .DropdownMenuRadioItem {
    font-size: 12px;
  }
  .DropdownMenuContent{
    min-width: 60px;
  }
}

@media screen and (min-width: 801px) {
  .language-picker {
    right: 40px;
    top: 45px;
  }
}