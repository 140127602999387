@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-MediumItalic.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('./Cabin-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: italic;
}

@font-face {
  font-family: 'Bolota';
  src: url('./Bolota-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bolota';
  src: url('./Bolota-Variable.ttf') format('truetype');
  font-weight: thin;
  font-style: normal;
}

@font-face {
  font-family: 'Bolota';
  src: url('./Bolota-Variable.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
}