.header {
  width: 100%;
  /* height: 50px; */
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  opacity: 1;
  /* padding: 10px; */
}

.header > #logo {
  margin-top: 10px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #F7F2EB;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}

th {
  background-color: #463442;
  font-weight: bold;
}

h1, h2, h3, th {
  font-family: Bolota;
}




@media (max-width: 1024px) {
  table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
  }
}

/* Responsive styles for phones */
@media (max-width: 768px) {
  table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
  }

  th, td {
      font-size: 14px;
      padding: 6px;
  }
}
