.social-links {
  position: absolute;
  z-index: 1;
}

@media screen and (min-width: 200px) and (max-width: 800px) { 
  .social-links {
    right: 10px;
    top: 45px;
    .social-icon {
      max-width: 32px;
      max-height: 32px;
      margin-left: 5px;
    }
  }
}

@media screen and (min-width: 801px) {
  .social-links {
    left: 15px;
    top: 45px;
    
    .social-icon {
      margin-left: 15px;
      max-width: 42px;
      max-height: 42px;
    }
  }
}