body {
  min-height: 100vh;
  width: 100%;
  background-color: black !important;
}
.App {
  text-align: center;
}

.App > .container {
  min-height: 100vh;
  width: 100%;
  background-color: black;
}

.section {
  width: 33.33%;
  height: 100vh;
  float: left;
  box-sizing: border-box;
  opacity: 0.85;
  transition: background-position 0.5s, opacity 0.5s;
  overflow: hidden;
  position: relative;
}
.section-overlay {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100vh;
  transition: all 0.7s;
  text-align: left;
}

.text-content > h1 {
  text-align: left; /* Center the text horizontally */
  transition: all 0.7s;
  font-family: Bolota;
  font-weight: bold;
  font-size: 60px;
  margin: 0px;
}

.text-content > h1:last-of-type {
  margin-top: -25px;
}

.text-content > span > p {
  text-align: left; /* Center the text horizontally */
  transition: all 0.7s;
  font-family: Bolota;
  font-weight: lighter;
  margin: 0;
  font-size: 24px;
  width: 90%;
}

.section-bg {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all 0.7s;
}

.button {
  cursor: pointer;
  padding: 15px 15px 10px 15px;
  border-radius: 20px;
  border: 0;
  margin-top: 20px;
  font-family: Bolota;
  font-weight: 200;
  font-size: large;
}

.small-button {
  cursor: pointer;
  padding: 10px 10px 7.5px 10px;
  border-radius: 20px;
  border: 0;
  margin-top: 10px;
  font-family: Bolota;
  font-weight: 200;
  font-size: 12px;
}

.button.bg-green:active {
  background-color: #BDD373;
}

.bg-green {
  background-color: #D3E2A2;
}

.button.bg-eggplant:active {
  background-color: #231A21;
}

.bg-eggplant {
  background-color: #463442;
}
.text-eggplant {
  color: #463442;
}
.text-green {
  color: #D3E2A2;
}

.text-white {
  color: #FFFFFF;
}

.modal-portal {
  background-color: #FFF;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #463442;
  background: rgba(70, 52, 66, 0.3);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.signup-form {
  color:#463442;
  padding: 0px 50px 10px 50px;
}

iframe > input {
  background-color: black;
}

.cursor-pointer {
  cursor: pointer;
}
.legal-links {
  width: 95%;
  height: 42px;
  text-align: right;
  position: absolute;
  padding: 5px;
  margin-bottom: 10px;
  bottom: 0px;
  z-index: 100;
  color: white !important;
}

.legal-links > a {
  padding-left: 10px;
  color: #BDD373;
  font-family: Bolota;
  font-weight: lighter;
  text-decoration: none;
}

.legal-links > .cookie-settings {
  text-decoration: none;
  font-size: 12px;
}

#hs-eu-cookie-confirmation #hs-eu-confirmation-button, #hs-eu-cookie-confirmation #hs-eu-decline-button, #hs-modal-accept-all, #hs-modal-save-settings {
  text-align: center !important;
  font-family: Bolota !important;
  font-weight: 200 !important;
  font-size: 12px !important;
  height: 40px;
  max-width: 60px;
} 

/* Small to Medium devices (landscape phones to tablets, 576px to 991px) */
@media screen and (min-width: 200px) and (max-width: 1159px) { 
  /* CSS rules for small devices */
  body {
    background-color: black;
  }
  .App > .container {
    position: relative;
    width: 100%;
    background-color: black;
  }
  .section {
    min-width: 100% !important;
    height: 90vh;
  }
  .text-content {
    position: absolute;
    bottom: 0px;
    padding-bottom: 50px;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    padding: 20px;
  }
  /* .section-overlay:last-of-type {
    padding-bottom: 100px !important;
  } */
  .text-content > h1 {
    top: 50%;
  }
  .text-content > span {
    opacity: 1;
    top: 45%;
    margin-bottom: 30px;
  }
  
  .legal-links {
    position: relative;
  }
  .legal-links > a {
    font-size: 8px;
    margin-bottom: 40px;
  }
  .legal-links > .cookie-settings {
    margin-bottom: 40px;
  }
}

@media (hover: none) and (min-width: 1160px) {
  .text-content {
    position: absolute;
    bottom: 0px !important;
    padding-bottom: 50px;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    padding: 20px;
  }

  .text-content > span {
    opacity: 1 !important;
    bottom: 55% !important;
  }
}

/* Large to Extra Large devices (desktops to large desktops, 992px and up) */
@media screen and (min-width: 1160px) {
  .section {
    width: 33.33%;
    height: 100vh !important;
  }
  .section-overlay {
    padding-top: 80%;
    transition: all 0.7s;
  }
  .text-content > span {
    opacity: 0;
    transition: all 0.7s;
  }

  .text-content {
    z-index: 1000;
    height: 600px;
    position: absolute;
    bottom: -200px;
    padding-bottom: 50px;
    transition: all 0.7s;
    background: linear-gradient(to top, rgb(0, 0, 0), rgba(0, 0, 0, 0));
    padding: 20px;
  }

  .section-overlay:hover {
    opacity: 1;
  }

  .section-overlay:hover > img {
    transform: scale(1.05);
  }

  .section-overlay:hover > .text-content {
    bottom: 0px;
  }

  .section-overlay:hover > .text-content > h1 {
    bottom: 50%;
    top: 40%;
  }

  .section-overlay:hover > .text-content > span {
    opacity: 1;
    bottom: 55%;
  }
}